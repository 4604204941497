import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Fade,
  Grid,
} from '@mui/material';
import {
  StyledBox,
  BoldTypography,
  LinkTypography,
  DangerousTypography,
  IconInfo,
  LinkButton,
  ArrowLink,
} from '@/components/molecules/site-stripe/SiteStripe.styles';
import Icon from '@/components/atoms/icon';
import { useDisplayEventTrackingOnMount } from '@/hooks/useDisplayEventTracking';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import TruncateMarkup from 'react-truncate-markup';

const markupCheck = /<\/?[a-z][\s\S]*>/i;
const markupRemoval = /(<([^>]+)>)/gi;
const defaultAriaLabel = 'Important Messages';

function getWeightedMessage(messages) {
  if (!messages?.length) return null;

  const sortedMessagesByWeight = [...messages]
    .sort((a, b) => b.priority.weight - a.priority.weight);

  const sortedMessagesByDate = sortedMessagesByWeight
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const message = sortedMessagesByDate[0];

  if (message.title) return { title: message.title, link: message.link };

  const cleanMessage = markupCheck.test(message.description) ? message.description.replace(markupRemoval, '') : message.description;

  return { title: cleanMessage, link: message.link };
}

function SiteStripeWrapper({ message, ariaLabel, children }) {
  return (
    <Fade in={!!message}>
      <StyledBox component="section" aria-label={ariaLabel}>
        <Container maxWidth="xl">
          <Grid container justifyContent="center" direction="row" wrap="nowrap">
            <Grid item display={{ xs: 'none', md: 'flex' }} alignItems="center" textAlign="end">
              <IconInfo id="site-stripe-icon-info-cms" icon="icon-info" aria-hidden />
            </Grid>
            <Grid item>
              {children}
            </Grid>
          </Grid>
        </Container>
      </StyledBox>
    </Fade>
  );
}

function CmsSiteStripeContent({
  message,
  ariaLabel,
  ctaText,
  ctaTarget,
  ctaUrl,
  componentName,
}) {
  useDisplayEventTrackingOnMount(
    componentName,
    [{ message }, { source: 'cms' }],
  );
  const trackClick = useClickEventTracking();
  return (
    <SiteStripeWrapper message={message} ariaLabel={ariaLabel}>
      <DangerousTypography variant="body4" component="span" dangerouslySetInnerHTML={{ __html: message }} />
      {ctaText && (
        <ArrowLink
          target={ctaTarget}
          aria-label={ctaText}
          href={ctaUrl}
          onClick={() => {
            trackClick(
              `${componentName}_ArrowLink`,
              [{ link: ctaUrl }, { text: ctaText }, { source: 'cms' }],
            );
          }}
        >
          <LinkTypography variant="body4" component="span">
            {ctaText}
          </LinkTypography>
          <Icon id="site-stripe-icon-long-arrow" icon="icon-long-arrow" aria-hidden />
        </ArrowLink>
      )}
    </SiteStripeWrapper>
  );
}

function AlertSiteStripeContent({
  message,
  ctaUrl,
  ariaLabel,
  componentName,
}) {
  useDisplayEventTrackingOnMount(
    componentName,
    [{ message }, { source: 'alert' }],
  );
  const trackClick = useClickEventTracking();
  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(true);
  return (
    <SiteStripeWrapper message={message} ariaLabel={ariaLabel}>
      <BoldTypography component="span" variant="body4">
        {truncated && !expanded ? (
          <TruncateMarkup
            lines={2}
            lineHeight={24}
            ellipsis={!expanded && (
            <>
              <BoldTypography component="span" variant="body4">...</BoldTypography>
              {' '}
              <LinkButton
                component="button"
                aria-label="Read More"
                onClick={() => {
                  setExpanded(true);
                  setTruncated(false);
                }}
              >
                <BoldTypography component="span" variant="body4">
                  Read More
                </BoldTypography>
              </LinkButton>
              {ctaUrl && (
              <ArrowLink
                target="_blank"
                aria-label="Learn More"
                href={ctaUrl}
                onClick={() => {
                  trackClick(
                    `${componentName}_ArrowLink`,
                    [{ link: ctaUrl }, { text: 'Learn More' }, { source: 'alert' }],
                  );
                }}
              >
                <LinkTypography component="span" variant="body4">
                  Learn More
                </LinkTypography>
                <Icon id="site-stripe-icon-long-arrow" icon="icon-long-arrow" aria-hidden />
              </ArrowLink>
              )}
            </>
            )}
          >
            <span>{message}</span>
          </TruncateMarkup>
        ) : <span>{message}</span>}
        {!truncated && expanded && (
          <>
            {' '}
            <LinkButton
              component="button"
              aria-label="Show Less"
              onClick={() => {
                setExpanded(false);
                setTruncated(true);
              }}
            >
              <BoldTypography component="span" variant="body4">
                Show Less
              </BoldTypography>
            </LinkButton>
            {ctaUrl && (
            <ArrowLink
              target="_blank"
              aria-label="Learn More"
              href={ctaUrl}
              onClick={() => {
                trackClick(
                  `${componentName}_ArrowLink`,
                  [{ link: ctaUrl }, { text: 'Learn More' }, { source: 'alert' }],
                );
              }}
            >
              <LinkTypography component="span" variant="body4">
                Learn More
              </LinkTypography>
              <Icon id="site-stripe-icon-long-arrow" icon="icon-long-arrow" aria-hidden />
            </ArrowLink>
            )}
          </>
        )}
      </BoldTypography>
    </SiteStripeWrapper>
  );
}
function SiteStripe({
  siteStripeOn,
  siteStripeAlerts,
  ariaLabel = defaultAriaLabel,
  message,
  ctaText,
  ctaUrl,
  ctaTarget = '_blank',
  parentComponentName,
}) {
  const componentName = `${parentComponentName}_SiteStripe`;
  if (siteStripeOn) {
    return (
      <CmsSiteStripeContent
        message={message}
        ariaLabel={ariaLabel}
        ctaText={ctaText}
        ctaTarget={ctaTarget}
        ctaUrl={ctaUrl}
        componentName={componentName}
      />
    );
  }

  const alertMessage = getWeightedMessage(siteStripeAlerts);

  if (alertMessage) {
    return (
      <AlertSiteStripeContent
        message={alertMessage.title}
        ariaLabel={defaultAriaLabel}
        ctaUrl={alertMessage.link}
        componentName={componentName}
      />
    );
  }

  return null;
}

SiteStripe.propTypes = {
  siteStripeOn: PropTypes.bool,
  siteStripeAlerts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      description: PropTypes.string,
      startDate: PropTypes.string,
      priority: PropTypes.shape({
        level: PropTypes.string,
        weight: PropTypes.number,
      }),
    }),
  ),
  ariaLabel: PropTypes.string,
  message: PropTypes.string,
  ctaText: PropTypes.string,
  ctaUrl: PropTypes.string,
  ctaTarget: PropTypes.string,
};

SiteStripe.defaultProps = {
  siteStripeOn: false,
  siteStripeAlerts: [],
  ariaLabel: defaultAriaLabel,
  message: '',
  ctaText: '',
  ctaUrl: '',
  ctaTarget: '_blank',
};

export default SiteStripe;
