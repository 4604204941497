import { useEffect, useState } from 'react';
import type { DVCVariableValue } from '@devcycle/js-client-sdk';
import {
  withDevCycleProvider,
  useDevCycleClient,
  useIsDevCycleInitialized,
  useVariableValue,
} from '@devcycle/react-client-sdk';
import { FeatureVariableKeys } from '@/helpers/features';

function FeatureProvider({ personId }: { personId: string }): null {
  const dvcClient = useDevCycleClient();
  const [dvcPersonId, setDvcPersonId] = useState<string>(null);

  useEffect(() => {
    if (personId && personId !== dvcPersonId) {
      setDvcPersonId(personId);
      dvcClient.identifyUser({
        user_id: personId,
        customData: {
          personId,
        },
      });
    }
  }, [personId, dvcPersonId, dvcClient]);

  return null;
}

const withFeatureProvider = (WrappedComponent: React.ComponentType) => withDevCycleProvider({
  sdkKey: process.env.NEXT_PUBLIC_DEVCYCLE_CLIENT_KEY,
  options: {
    deferInitialization: true,
  },
})(WrappedComponent);

const useIsFeatureProviderInitialized = () => useIsDevCycleInitialized();

const useFeatureVariableValue = <T extends DVCVariableValue>(
  key: FeatureVariableKeys,
  defaultValue: T,
  useCache: boolean = true,
) => {
  const isInitialized = useIsDevCycleInitialized();
  const dvcVariableValue = useVariableValue<T>(key, defaultValue);
  return (!isInitialized && !useCache) ? defaultValue : dvcVariableValue;
};

export {
  withFeatureProvider,
  useIsFeatureProviderInitialized,
  FeatureProvider,
  useFeatureVariableValue,
};
