import { SessionUser } from 'next-auth';
import { getSession, GetSessionParams, signOut } from 'next-auth/react';
import { getItem as getFromSessionStorage } from '@/helpers/session-storage';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';
import configs from '@/helpers/config';
import {
  TOKEN_STORAGE_KEY,
  PERSON_ID_STORAGE_KEY,
  EMAIL_ID_STORAGE_KEY,
  CUSTOM_TOKEN_STORAGE_KEY,
  SIGN_OUT_CALLBACK,
  NULL_USER,
} from './constants';

const { ecampus, multimedia, myphx } = configs;

const updateSessionStorage = (key: string, value?: string) => {
  if (value) {
    sessionStorage.setItem(key, value);
  } else {
    sessionStorage.removeItem(key);
  }
};

// Cleared for use with Brent
export const setUserInSessionStorage = (user: SessionUser) => {
  if (typeof window !== 'undefined') {
    updateSessionStorage(PERSON_ID_STORAGE_KEY, user?.personId);
    updateSessionStorage(EMAIL_ID_STORAGE_KEY, user?.email);
    updateSessionStorage(TOKEN_STORAGE_KEY, user?.token);
  }
};

export const getTokenFromSessionStorage = () => getFromSessionStorage(TOKEN_STORAGE_KEY);

export const getCustomTokenFromSessionStorage = (
  () => getFromSessionStorage(CUSTOM_TOKEN_STORAGE_KEY)
);

export const getPersonIdFromSessionStorage = () => {
  const scenarioId = getFromSessionStorage(MOCK_SCENARIO_KEY) || null;
  return scenarioId || getFromSessionStorage(PERSON_ID_STORAGE_KEY);
};

export const getEmailIdFromSessionStorage = (
  () => getFromSessionStorage(EMAIL_ID_STORAGE_KEY)
);

const getUserProperty = async (
  storageKey: string,
  propertyKey: 'token' | 'personId',
  context: GetSessionParams,
) => {
  const sessionStorageItem = getFromSessionStorage(storageKey);

  // Try and pull from session storage to avoid a server side check
  if ((sessionStorageItem || '') !== '') {
    return sessionStorageItem;
  }

  // Will always be hit if a server side call is made
  const session = await getSession(context);

  // Consumer will need to check token and re-auth if needed
  switch (propertyKey) {
    case 'token':
      return session?.user?.token;
    case 'personId':
      return session?.user?.personId;
    default:
      return null;
  }
};

/*
Preferred method to use for any http request as it handles token retrieval from client or server.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getToken = async (
  context?: GetSessionParams,
) => getUserProperty(TOKEN_STORAGE_KEY, 'token', context);

/*
Preferred method to use for any http request as it handles token retrieval from client or server.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getPersonId = async (
  context?: GetSessionParams,
) => getUserProperty(PERSON_ID_STORAGE_KEY, 'personId', context);

/*
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const isUserLoggedIn = async (context: GetSessionParams) => {
  const session = await getSession(context);

  return !!session?.user;
};

/*
Server side specific method to get user session data in one trip.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getSessionData = async (context: GetSessionParams) => {
  const session = await getSession(context);
  return {
    isUserLoggedIn: !!session?.user,
    token: session?.user?.token,
    personId: session?.user?.personId,
  };
};

const requestTimeoutHandler = () => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 5000);
  // Allow Node.js processes to exit early if only the timeout is running
  timeoutId?.unref?.();
  return controller.signal;
};

export async function signOutOfExternalUOPNextApp(externalUOPBaseUrl: string) {
  const baseUrl = `${externalUOPBaseUrl}api/auth`;
  const csrfTokenResponse = await fetch(`${baseUrl}/csrf`);
  const csrfTokenData = await csrfTokenResponse.json();
  const { csrfToken } = csrfTokenData;
  const res = await fetch(`${baseUrl}/signout`, {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Auth-Return-Redirect': '0',
    },
    body: new URLSearchParams({ csrfToken }),
  });
  const data = await res.json();

  return data;
}

export function logoutOfExternalApplication(url: string, method = 'GET') {
  return new Promise((resolve) => {
    if (url) {
      fetch(url, {
        method,
        credentials: 'include',
        signal: requestTimeoutHandler(),
      }).then((response) => {
        resolve(response?.ok);
      }).catch(() => {
        resolve(false);
      });
    } else {
      resolve(false);
    }
  });
}

export const constructExternalApplicationLogoutUrl = (
  baseUrl: string,
  logoutUrl: string,
  logoutEnabled: string,
): string => {
  if (logoutEnabled?.toLowerCase() === 'true' && baseUrl && logoutUrl) {
    return `${baseUrl}${logoutUrl}`;
  }
  return null;
};

export function logout() {
  setUserInSessionStorage(NULL_USER);
  return Promise.allSettled([
    logoutOfExternalApplication(
      constructExternalApplicationLogoutUrl(
        multimedia.baseUrl,
        multimedia.logoutPath,
        multimedia.logoutEnabled,
      ),
    ),
    logoutOfExternalApplication(
      constructExternalApplicationLogoutUrl(
        ecampus.baseUrl,
        ecampus.logoutPath,
        ecampus.logoutEnabled,
      ),
    ),
    signOutOfExternalUOPNextApp(`${myphx.baseUrl}/profile/`),
  ]).then(() => {
    signOut({
      callbackUrl: SIGN_OUT_CALLBACK,
    });
  });
}

export function setAuthCookies(useSecureCookies = true, sameSite = 'lax') {
  const cookiePrefix = useSecureCookies ? '__Secure-' : '';
  const appCookiePrefix = 'myphx';
  const basePath = '/';
  const httpOnly = true;
  return {
    // default cookie options
    sessionToken: {
      name: `${cookiePrefix}${appCookiePrefix}.session-token`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
      },
    },
    callbackUrl: {
      name: `${cookiePrefix}${appCookiePrefix}.callback-url`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
      },
    },
    csrfToken: {
      // Default to __Host- for CSRF token for additional protection if using useSecureCookies
      // NB: The `__Host-` prefix is stricter than the `__Secure-` prefix.
      name: `${useSecureCookies ? '__Host-' : ''}${appCookiePrefix}.csrf-token`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
      },
    },
    pkceCodeVerifier: {
      name: `${cookiePrefix}${appCookiePrefix}.pkce.code_verifier`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
        maxAge: 604800, // 1 week in seconds
      },
    },
    state: {
      name: `${cookiePrefix}${appCookiePrefix}.state`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
        maxAge: 604800, // 1 week in seconds
      },
    },
    nonce: {
      name: `${cookiePrefix}${appCookiePrefix}.nonce`,
      options: {
        httpOnly,
        sameSite,
        path: basePath,
        secure: useSecureCookies,
        maxAge: 604800, // 1 week in seconds
      },
    },
  };
}
