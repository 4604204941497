// TODO: LET-14582 All this should move to src/helpers/feature-provider/index.ts
const features = {
  purgeErrorOnReloadEnabled:
    process.env.NEXT_PUBLIC_FEATURE_PURGE_ON_ERROR_RELOAD_ENABLED === 'true',
  canaryTextEnabled:
    process.env.NEXT_PUBLIC_FEATURE_CANARY_TEXT_ENABLED === 'true',
  // SSR
  ssrRtkQueryHydrationEnabled:
    process.env.NEXT_FEATURE_SSR_HYDRATE_RTK === 'true',
  // Debugging
  performanceMarksEnabled:
    process.env.NEXT_PUBLIC_FEATURE_PERFORMANCE_MARKS_ENABLED === 'true',
};

enum FeatureVariableKeys {
  AttendanceTileBlackoutEndDate = 'attendance-tile-blackout-end-date',
  AttendanceTileEnabledCourses = 'attendance-tile-enabled-courses',
  loaMyphoenixRollout = 'loa-myphoenix-rollout',
  loaUnforeseenPhase = 'loa-unforeseen-circumstance-phase-2',
  loaEditPhase = 'loa-edit-phase-2',
  loaAdaCalendar = 'ada-calendar-phase-1',
  CbeEngagementTileBlackoutEndDate = 'cbe-engagement-tile-blackout-end-date',
  DaEngagementTileBlackoutEndDate = 'da-engagement-tile-blackout-end-date',
  ProspectConversionsRegisteredAndNotFirstCourseCompleted = 'pc-registered-not-1st-course-completed',
  MfeCareersStepCard = 'mfe-careers-step-card',
  PreFetchValidation = 'pre-fetch-validation',
  HomePageInterrupter = 'home-page-interrupter',
  MessageCenterEnrollmentTasks = 'message-center-enrollment-tasks',
  PLAUpliftRollout = 'pla-uplift-rollout',
  MfeHeaderComponent = 'mfe-header-component',
}

const FeatureVariableValues = {
  MfeHeaderComponent: {
    MfeHeader: 'mfe',
    LegacyHeader: 'legacy',
    PublicHeader: 'public',
  },
};

export default features;

export { FeatureVariableKeys, FeatureVariableValues };
