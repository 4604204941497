import PropTypes from 'prop-types';
import {
  LinkWrapper, StyledLink, StyledIcon,
} from '@/components/atoms/logout-icon-link/LogoutIconLink.styles';
import { logout } from '@/helpers/auth';

function LogoutIconLink({ logoutText }) {
  return (
    <LinkWrapper>
      <StyledLink
        onClick={() => logout()}
        href="#"
      >
        <StyledIcon
          id="header-logout-icon"
          icon="icon-logout"
        />
        <span>
          {logoutText}
        </span>
      </StyledLink>
    </LinkWrapper>
  );
}

LogoutIconLink.propTypes = {
  logoutText: PropTypes.string,
};

LogoutIconLink.defaultProps = {
  logoutText: 'Logout',
};

export default LogoutIconLink;
