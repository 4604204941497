import { baseApi2 } from '@/store/queries';
import { studentEndpoints } from '@/store/domains/resources/student';
import { MembershipDetails, StudentLoaDetails } from '@/types/api/student/membershipDetails';
import { DateTime } from 'luxon';
import resolveESTGraduationPostData from '@/myphoenix/utils/estimate-date-postdata';
import { StudentCourseMembershipResponse, StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { PLAUploadResponse, PLASubmission, PLAUploadRequest } from '@/types/api/student/pla';

const RANGE = {
  min: parseInt(process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MINIMUM, 10) || -455,
  max: parseInt(process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MAXIMUM, 10) || 180,
};

const rangeMin = DateTime.now().plus({ days: RANGE.min }).toISODate().toString() || '';
const rangeMax = DateTime.now().plus({ days: RANGE.max }).toISODate().toString() || '';

export const studentApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getAllProgramsByPersonId: build.query<MembershipDetails[], { personId: string }>(
      { query: ({ personId }) => ({ url: studentEndpoints.getAllProgramsByPersonId(personId), method: 'GET' }) },
    ),
    getMembershipsByPersonIdAndDateRange:
      build.query<StudentCourseMembership[], {
        personId: string,
        min?: string,
        max?: string
      }>(
        {
          query: ({ personId, min = rangeMin, max = rangeMax }) => ({ url: studentEndpoints.getMembershipsByPersonIdAndDateRange(personId, min, max), method: 'GET' }),
          transformResponse: (response: StudentCourseMembershipResponse) => {
            const filteredMemberships = response?.memberships
              ?.filter(({ startDate, endDate, offeringType, participantId }) => (
                startDate && endDate && offeringType !== 'TT' && participantId
              ));

            const membershipsWithId = filteredMemberships?.map((membership) => ({
              ...membership,
              id: membership.id || `OSIRIS:${membership?.participantId}`,
            }));

            return membershipsWithId;
          },
        },
      ),

    getStudentLoa: build.query<StudentLoaDetails, { personId: string }>(
      {
        query: ({ personId }) => ({ url: studentEndpoints.getLoaByPersonId(personId), method: 'GET' }),
        transformResponse: (response: StudentLoaDetails) => {
          if (response?.currentLOA?.startDate && response?.currentLOA?.endDate) {
            return response;
          }
          return null;
        },
      },
    ),

    getPLA: build.query<PLASubmission, { irn: string }>({
      query: ({ irn }) => ({
        url: studentEndpoints.getPLA(irn),
        method: 'GET',
      }),
      providesTags: ['PLA'],
    }),
    uploadDocument: build.mutation<PLAUploadResponse, PLAUploadRequest>({
      query: ({ irn, file, documentCategory }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('IRN', irn);
        formData.append('documentCategory', documentCategory);
        return {
          url: studentEndpoints.uploadDocument(),
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['PLA'],
    }),
    submitApplication: build.mutation<{}, { irn: string, applicationId: string }>({
      query: ({ irn, applicationId }) => ({
        url: studentEndpoints.submitApplication(irn, applicationId),
        method: 'POST',
      }),
      invalidatesTags: ['PLA'],
    }),
    deleteDocument: build.mutation<boolean, { irn: string, fileId: string }>({
      query: ({ irn, fileId }) => ({
        url: studentEndpoints.deleteDocument(irn, fileId),
        method: 'DELETE',
      }),
      invalidatesTags: ['PLA'],
    }),
    getEstimatedGraduationDate:
      build.query<any, { requestBody: any }>(
        {
          query: ({ requestBody }) => ({
            url: studentEndpoints.getEstimatedGraduationDate(),
            method: 'POST',
            body: resolveESTGraduationPostData(requestBody),
          }),
        },
      ),
  }),
});

export const {
  useGetAllProgramsByPersonIdQuery,
  useGetMembershipsByPersonIdAndDateRangeQuery,
  useGetStudentLoaQuery,
  useGetPLAQuery,
  useUploadDocumentMutation,
  useSubmitApplicationMutation,
  useDeleteDocumentMutation,
  useGetEstimatedGraduationDateQuery,
} = studentApi;
