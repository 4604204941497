import Config from '@/helpers/config';
import { navigate } from '@/helpers/window-functions';

const LOGON_EVENT_BYPASSED_KEY = 'logon-event-bypassed';
const { ecampus: { baseUrl: ECAMPUS_BASE_URL, homepagePath: ECAMPUS_HOME_PAGE_PATH } } = Config;

export const logonEventRedirect = () => {
  sessionStorage.setItem(LOGON_EVENT_BYPASSED_KEY, 'true');
  const eCampusHomepageUrl = `${ECAMPUS_BASE_URL}${ECAMPUS_HOME_PAGE_PATH}`;
  navigate(eCampusHomepageUrl, '_self');
};

export const logonEventShouldBeBypassed = () => sessionStorage.getItem(LOGON_EVENT_BYPASSED_KEY) === 'true';
