import PropTypes from 'prop-types';
import {
  StyledHeader, StyledList, StyledItem, StyledLink,
} from '@/components/molecules/link-list/LinkList.styles';
import { useClickEventTracking } from '@/hooks/useClickEventTracking';

function LinkList({
  header, links, footer, parentComponentName,
}) {
  const trackClick = useClickEventTracking();
  const componentName = `${parentComponentName}_LinkList`;
  return (
    <div
      role="navigation"
      aria-label={header}
    >
      <StyledHeader
        variant="body5"
        id={`${header}-navigation-h2`}
        footer={footer}
      >
        {header}
      </StyledHeader>
      <StyledList>
        {links.map((item) => (
          <StyledItem
            key={item.text}
          >
            <StyledLink
              href={item.url}
              targetOverride={item.target}
              underline="hover"
              variant="body5"
              footer={footer}
              onClick={() => {
                trackClick(
                  `${componentName}_Link`,
                  [
                    { url: item.url },
                    { target: item.target },
                    { text: item.text },
                    { styleType: header ? 'header' : 'footer' },
                  ],
                );
              }}
            >
              {item.text}
            </StyledLink>
          </StyledItem>
        ))}
      </StyledList>
    </div>
  );
}

LinkList.propTypes = {
  header: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }).isRequired,
  ),
  footer: PropTypes.bool,
};

LinkList.defaultProps = {
  links: [],
  footer: false,
};

export default LinkList;
