const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const studentEndpoints = {
  getAllProgramsByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/program/membershipsdetails?$filter=personId eq '${personId}'`,
  getProgramProgressDataByProgramId: (
    programId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/program/detailprogression?$filter=studentProgramId eq ${programId}`,
  getMembershipsByPersonIdAndDateRange: (
    personId: string,
    min: string,
    max: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
  getLoaByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/loa/leaveofabsence?$filter=personId eq ${personId}`,
  getPLA: (
    irn: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/pla?$filter=irn eq '${irn}'`,
  uploadDocument: (
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/pla/documents`,
  submitApplication: (
    irn: string,
    applicationId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/pla/applications/${applicationId}?IRN=${irn}`,
  deleteDocument: (
    irn: string,
    fileId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/pla/documents/${fileId}?IRN=${irn}`,
  getEstimatedGraduationDate: (
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/stateless/ruleset/estimatedGraduationDate`,

  getCourseOutcomes: (
    programId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/course/memberships/outcome?csph=${programId}`,
};

export default studentEndpoints;
