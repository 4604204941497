import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import StandardLink from '@/components/atoms/standard-link';
import {
  StyledItem,
  StyledAccordion,
  Summary,
  StyledExpandIcon,
  SummaryHeader,
  StyledDetails,
  DetailsContent,
} from '@/components/molecules/accordion/Accordion.styles';

export default function Accordion(props) {
  const {
    header,
    links,
    styleType,
    sectionIndex,
    expandIcon,
    collapseIcon,
    externalExpanded,
    onChange,
    multiItem,
    parentId,
    children,
  } = props;

  const id = `accordion${sectionIndex}`;
  const [expanded, setExpanded] = React.useState(externalExpanded);

  useEffect(() => {
    if (externalExpanded !== null) {
      setExpanded(externalExpanded);
    }
  }, [externalExpanded]);

  const linkItems = links.map((item) => (
    <StyledItem
      $styleType={styleType}
      key={item.text}
    >
      <StandardLink
        href={item.url}
        targetOverride={item.target}
      >
        {item.text}
      </StandardLink>
    </StyledItem>
  ));

  return (
    <StyledAccordion
      id={id}
      $styleType={styleType}
      square
      elevation={0}
      onChange={onChange || (() => { setExpanded(!expanded); })}
      expanded={expanded}
    >
      <Summary
        id={`${id}Summary`}
        aria-controls={`${id}Details`}
        expandIcon={(
          <StyledExpandIcon
            $styleType={styleType}
            id={`${parentId}-accordion-collapse-expand-${sectionIndex}`}
            icon={expanded ? collapseIcon : expandIcon}
            aria-hidden="true"
          />
        )}
      >
        <SummaryHeader variant="body4" component="h3" $styleType={styleType} $expanded={expanded}>
          {header}
        </SummaryHeader>
      </Summary>
      <StyledDetails>
        <DetailsContent
          variant="body4"
          component="div"
        >
          {children}
          {!multiItem && (
            <ul>
              {linkItems}
            </ul>
          )}
        </DetailsContent>
      </StyledDetails>
    </StyledAccordion>
  );
}

Accordion.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      target: PropTypes.string,
    }),
  ),
  styleType: PropTypes.string,
  sectionIndex: PropTypes.number.isRequired,
  expandIcon: PropTypes.string,
  collapseIcon: PropTypes.string,
  externalExpanded: PropTypes.bool,
  onChange: PropTypes.func,
  multiItem: PropTypes.bool,
  parentId: PropTypes.string,
  children: PropTypes.node,
};

Accordion.defaultProps = {
  header: '',
  links: [],
  styleType: 'default',
  expandIcon: 'icon-toggle-open',
  collapseIcon: 'icon-toggle-close',
  externalExpanded: null,
  onChange: null,
  multiItem: false,
  parentId: '',
  children: null,
};
